import { green, pink, purple, yellow } from '../colors';
import '../index.css';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import MovingBanner from './MovingBanner'

export default function Navbar(){
    const [tabChanged, setTabChanged] = useState(false)

    const handleSetTabChangedOnClick = ()=> {
        setTabChanged(true);
    }

    const tabNames = [
        'home',
        'contact',
        'about'
    ]

    const [currentTab, setCurrentTab] = useState(getCurrentTab(tabNames))

    const [logoColors, setLogoColors] = useState(getLogoColors(currentTab));

    const [homeTabColor, setHomeTabColor] = useState(getTabColor('home', currentTab));
    const [contactTabColor, setContactTabColor] = useState(getTabColor('contact', currentTab));
    const [aboutTabColor, setAboutTabColor] = useState(getTabColor('about', currentTab));

    useEffect(() => {
        setCurrentTab(getCurrentTab(tabNames));

        setLogoColors(getLogoColors(currentTab));

        setHomeTabColor(getTabColor('home', currentTab));
        setContactTabColor(getTabColor('contact', currentTab));
        setAboutTabColor(getTabColor('about', currentTab));

        setTabChanged(false);
    }, [tabChanged])

    return(
        <div className="content-centralised-container" >
                    <div className="navbar-element-container">
                        <h1 className="navbar-heading">
                            <span style={{color: logoColors[0]}}>Jack</span> <span style={{color: logoColors[1]}}>Grbovic</span>
                        </h1>
                
                        <ul className="nav-links" style={{marginBottom: '2px', color: 'white', marginRight: '10px'}}>
                            <li onClick={handleSetTabChangedOnClick}>
                                <NavLink style={{color: homeTabColor}} to="/" className={'navbar-option navbar-home-element'}>Home</NavLink>
                            </li>
                            <li onClick={handleSetTabChangedOnClick}>
                                <NavLink style={{color: contactTabColor}} to="/contact" className={'navbar-option navbar-contact-element'}>Contact</NavLink>
                            </li>
                            <li onClick={handleSetTabChangedOnClick}>
                                <NavLink style={{color: aboutTabColor}} to="/about" className={'navbar-option navbar-about-element'}>About</NavLink>
                            </li>
                        </ul>
                    </div>
            <MovingBanner />
        </div>
    )
}

function getCurrentTab(tabNames){
    const href = window.location.href;

    const hrefSegments = href.split('/');

    const parsedTabName = tabNames.find((tabName) => {
        if (hrefSegments[hrefSegments.length-1] === tabName){
            return tabName;
        } 
    })

    return parsedTabName ?? '';
}

function getTabColor(tabName, currentTab){
    if (!currentTab && tabName === 'home') return pink;

    else if (tabName === currentTab && tabName === 'contact') return green;

    else if (tabName === currentTab && tabName === 'about') return purple;
}

function getLogoColors(tabName){
    if (!tabName) return [pink, purple];

    else if (tabName === 'contact') return [purple, green];

    else if (tabName === 'about') return [green, pink];
}