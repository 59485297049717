import customerJourneyPrototypeImageImport from '../../../images/customerJourney/9E6D63F1-5FD1-43ED-AEA7-593FF5D71A47_1_201_a.jpeg'

export const customerJourneyPrototypeImageExport = customerJourneyPrototypeImageImport;

export const customerJourneyTitle = `Customer Journey`

export const customerJourneyShortDescription = `A plugin for Salesforce which assists in simplifying complex deals`;

export const customerJourneyFullDescription = `This is a plugin that is currently in development. It focuses on enhancing the existing 
experience of using the Salesforce CRM, by allowing the users to focus on specific areas of a deal and improves communication and the
sharing of information surrounding it. The image below is what was a very early prototype. Since it's still in development, this is all I can share so far.`