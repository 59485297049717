import { yellow, pink, purple, green } from "../colors";
import { React, useState } from "react";

export default function MovingBanner(){
    const [bannerStringList] = useState([
        {
            text: "Web Development",
            color: yellow,
        },
        {
            text: "Bespoke Applications",
            color: pink,
        },
        {
            text: "Shopify Theme Customisation",
            color: green,
        },
        {
            text: "UI/UX Design",
            color: purple,
        },
        {
            text: "Tailored Styling",
            color: pink,
        },
        {
            text: "Shopify App Development",
            color: green,
        }
    ]);

    return(
        <div className="banner-border-wrapper">
            <div className="spinner">
                <div className="strings-space-between-container">
                    {bannerStringList.map((bannerString, index) => {
                        return (
                            <BannerString text={bannerString.text} color={bannerString.color} key={index} />
                        )
                    })}
                </div>
            </div>
            <div className="spinner">
                <div className="strings-space-between-container">
                    {bannerStringList.map((bannerString, index) => {
                        return (
                            <BannerString text={bannerString.text} color={bannerString.color} key={index} />
                        )
                    })}
                </div>
            </div>
            <div className="spinner">
                <div className="strings-space-between-container">
                    {bannerStringList.map((bannerString, index) => {
                        return (
                            <BannerString text={bannerString.text} color={bannerString.color} key={index} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function BannerString({text, color}){
    return(
        <h2 className="banner-string" style={{color: color}}>
            {text}
        </h2>
    )
}