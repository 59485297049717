import { useState, React } from 'react';
import { yellow, pink, green, purple  } from '../colors';
import MessageSent from './Home/MessageSent';

export default function ContactForm(){
    const [messageSentBool, setMessageSentBool] = useState(false);

    const [errors, setErrors] = useState();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleSetFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.status === 400) {
                setErrors(data.errors);
            } else if (response.status === 200) {
                setErrors({});
                setMessageSentBool(true);
            }
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    function ErrorMessage({errorType}){
        if(errors){
            const errorsArray = errors.errors;
            
            const specifiedError = errorsArray.find((error) =>{
                if (error.path === errorType){
                    return error;
                }
                else return null;
            })

            if(!specifiedError) return;

            const errorMessage = specifiedError.msg;

            return (
                <span className="red-text">{errorMessage}</span>
            )
        }
        return;
    }


    return(
        (
            messageSentBool === true ? 
            <MessageSent /> :
            <div className="content-centralised-container">
                <div className='page-top-spacer'></div>
                <h1 className="page-title">Contact Me</h1>
                <div className="contact-form-body">
                    <form onSubmit={handleSubmit}>
                        <div className="flex-wrap-container">
                            <label htmlFor="name" className='input-label'>Name</label>
                            <input type="text" name="name" className="input" style={{color: yellow}} onChange={handleSetFormData} />
                            <ErrorMessage errorType={'name'} />
                        </div>
                        <div className="flex-wrap-container">
                            <label htmlFor="email" className='input-label'>Email</label>
                            <input type="text" name="email" className="input" style={{color: pink}} onChange={handleSetFormData} />
                            <ErrorMessage errorType={'email'} />
                        </div>
                        <div className="flex-wrap-container">
                            <label htmlFor="subject" className='input-label'>Subject</label>
                            <input type="text" name="subject" className="input" style={{color: green}} onChange={handleSetFormData} />
                            <ErrorMessage errorType={'subject'} />
                        </div>
                        <div className="flex-wrap-container">
                            <label htmlFor="message" className='input-label'>Message</label>
                            <textarea name="message" className="input" style={{color: purple}} onChange={handleSetFormData}></textarea>
                            <ErrorMessage errorType={'message'} />
                        </div>
                        <button type="submit" className="contact-form-button">Send Message</button>
                    </form>
                </div>
                <div className='page-bottom-spacer'></div>
            </div>
        )
        
    )
}