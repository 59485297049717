import addItemsWithResourcePickerImageImport from '../../../images/stockTakeApp/add-items-with-resource-picker.jpeg';
import createEmptyStockTakeImageImport from '../../../images/stockTakeApp/create-stock-take-empty-state.jpeg';
import stockTakeInProgressImageImport from '../../../images/stockTakeApp/stock-take-in-progress.jpeg';
import deleteStockTakePopUpImageImport from '../../../images/stockTakeApp/delete-stock-take-pop-up.jpeg';
import processStockTakePopUpImageImport from '../../../images/stockTakeApp/process-stock-take-pop-up.jpeg';

export const addItemsWithResourcePickerImage = addItemsWithResourcePickerImageImport;
export const createStockTakeEmptyStateImage = createEmptyStockTakeImageImport;
export const stockTakeInProgressImage = stockTakeInProgressImageImport;
export const deleteStockTakePopUpImage = deleteStockTakePopUpImageImport;
export const processStockTakePopUpImage = processStockTakePopUpImageImport;



export const stockTakeManagementAppShortDescription = `A custom Shopify plugin that makes managing stocktakes more efficient`;

export const stockTakeManagementAppFullDescription = ``;

export const stockTakeManagementAppTitle = `Stock Take App`;


export const stockTakeManagementAppFullOverview = `This is a bespoke plugin for a Shopify Store. The client needed a more effective way to
conduct their stock takes than what Shopify offers with its stock utilities. This app leverages Shopify's Resource Picker to allow
to user to make a selection of products that exist in the shop's inventory, that they would like to edit the inventory quantities of.
This selection is then rendered into a List, using Polaris (Shopify's library of visual components, in this case in React), and each
entry is displayed with its thumbnail image, the title, and an input box that is pre-filled with the existing inventory quanitity of the
item (these only accept numerical values). The user can save the stock take for later, delete it entirely or process it. Before saving 
or processing, there is a check to ensure the inventory location of the stock take has been specified. It uses Prisma to save a stock take
for later and GraphQL to query shop data and make mutations.`

export const createStockTakeEmptyStateImageDescription = `This is the Polaris empty state. It's what Shopify advises you to display when 
there's no active form and you want to encourage the user to take an action.`

export const resourcePickerImageDescription = `This is Shopify's Resource Picker. You use it to build a selection of items to be returned as
a list. They're returned as GraphQL objects, which we then use to populate the listings in our form, as well as use one of the properties
to load the featured image for the thumbnail of the product. When a stock take is saved, each object in the list is mapped to become a
simpler version of itself with minimal properties to find it again. We write these into the app's Prisma db, and when we come back to
the same stock take, the app uses these properties to populate the UI list in the same way we saw it before.`

export const updateStockTakePopUpImageDescription = `I also put in necessary form failsafes.`