import { useEffect } from "react";
import ImageBlock from "../../../PageComponents/ImageBlock";
import ProjectPageTitle from "../../../PageComponents/ProjectPageTitle";
import { grobsJobsFeaturedImage, 
    grobsJobsFullOverview, 
    grobsJobsViewJobPostImage,
    grobsJobsInboxOverviewImage,
    grobsJobsStartMessageThreadImage,
    grobsJobsViewMessageThreadImage,
    grobsJobsLoginImage,
    grobsJobsPaginationImage,
    grobsJobsRegisterImage,
    grobsJobsCreateJobImage,
    grobsJobsViewJobPostImageDescription,
    grobsJobsInboxOverviewImageDescription,
    grobsJobsStartMessageThreadImageDescription,
    grobsJobsViewMessageThreadImageDescription,
    grobsJobsLoginImageDescription,
    grobsJobsPaginationImageDescription,
    grobsJobsRegisterImageDescription,
    grobsJobsCreateJobImageDescription
} from "./GrobsJobsData";

export default function GrobsJobsProjectPage(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="content-centralised-container" style={{marginBottom: "50px"}}>
            <div className='page-top-spacer'></div>
            <ProjectPageTitle title={`Grob's Jobs`}/>
            <p className="general-block-of-text" style={{marginTop: "50px"}}>{grobsJobsFullOverview}</p>
            <ImageBlock imageFileName={grobsJobsFeaturedImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsViewJobPostImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsViewJobPostImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsInboxOverviewImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsInboxOverviewImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsStartMessageThreadImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsStartMessageThreadImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsViewMessageThreadImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsViewMessageThreadImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsLoginImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsLoginImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsPaginationImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsPaginationImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsRegisterImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsRegisterImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{grobsJobsCreateJobImageDescription}</p>
            <ImageBlock imageFileName={grobsJobsCreateJobImage}/>
        </div>
    )
}