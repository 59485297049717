import featuredImage from "../../images/stockTakeApp/add-items-with-resource-picker.jpeg";

export const ServicesOverview = () => {
    return(
        <p className="large-text-for-image-block" style={{maxWidth: '90%'}}>
            I do <span style={{color: '#fcba03'}}>web development.</span> I make <span style={{color: '#db48c3'}}>custom sites</span> from scratch and do the <span style={{color: '#6d53cf'}}>UI/UX</span> for them. I also write <span style={{color: '#87d96f'}}>bespoke apps</span> and <span style={{color: '#87d96f'}}>themes</span> for <span style={{color: '#87d96f'}}>Shopify stores.</span>
        </p>
    )
} 

export const servicesFeaturedImage = featuredImage;

export const title = 'Grobs Synth';