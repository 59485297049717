import { useEffect } from "react";
import ImageBlock from "../../../PageComponents/ImageBlock";
import ProjectPageTitle from "../../../PageComponents/ProjectPageTitle";
import { 
    addItemsWithResourcePickerImage,
    createStockTakeEmptyStateImage,
    stockTakeInProgressImage,
    stockTakeManagementAppFullOverview,
    createStockTakeEmptyStateImageDescription,
    resourcePickerImageDescription,
} from "./StockTakeManagementAppData";

export default function StockTakeManagementAppProjectPage(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="content-centralised-container" style={{marginBottom: "50px"}}>
            <div className='page-top-spacer'></div>
            <ProjectPageTitle title={`Stock Take App`}/>
            <p className="general-block-of-text" style={{marginTop: "20px"}}>{stockTakeManagementAppFullOverview}</p>
            <ImageBlock imageFileName={stockTakeInProgressImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{createStockTakeEmptyStateImageDescription}</p>
            <ImageBlock imageFileName={createStockTakeEmptyStateImage} />
            <p className="general-block-of-text" style={{marginTop: "80px"}}>{resourcePickerImageDescription}</p>
            <ImageBlock imageFileName={addItemsWithResourcePickerImage} />
            {/* <p className="general-block-of-text" style={{marginTop: "80px"}}>{updateStockTakePopUpImageDescription}</p>
            <ImageBlock imageFileName={deleteStockTakePopUpImage} />
            <ImageBlock imageFileName={processStockTakePopUpImage} /> */}
        </div>
    )
}