//Main Colors
export const yellow = "#fcba03";
export const pink = "#db48c3";
export const green = "#87d96f";
export const purple = "#6d53cf";

//Border Colors
export const lightPurple = "rgba(136, 108, 235, 0.8)";
export const lightGreen = "rgba(230, 255, 171, 0.8)";
export const lightYellow = "rgba(250, 196, 45, 0.8)";
export const lightPink = "rgba(227, 104, 207, 0.8)";