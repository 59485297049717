import featuredImage from "../../../images/grobsJobs/E61CE9ED-04C0-46F1-AF3E-A78826DC29FD_1_201_a.jpeg"
import viewJobPostImage from "../../../images/grobsJobs/D488A207-F705-495D-92AE-D206D954AF51_1_201_a.jpeg"
import inboxOverviewImage from "../../../images/grobsJobs/BD80B215-77B7-4FAA-B694-5BDB394A0714_1_201_a.jpeg"
import startMessageThreadImage from "../../../images/grobsJobs/B39F65FD-EF0C-46E4-801F-0506A5611E7A_1_201_a.jpeg"
import viewMessageThreadImage from "../../../images/grobsJobs/A653628B-581F-48EA-AA08-ED6BD0826C36_1_201_a.jpeg"
import loginImage from "../../../images/grobsJobs/94867274-69C8-4F5F-8F00-A0B23799411E_1_201_a.jpeg"
import paginationImage from "../../../images/grobsJobs/7700520E-5FDB-4B3B-9787-9BD33031FF18_1_201_a.jpeg"
import registerImage from "../../../images/grobsJobs/81E445E6-3AB1-46D1-A1BD-B7D3A0FAAED5_1_201_a.jpeg"
import createJobImage from "../../../images/grobsJobs/45ACC65E-ECA5-495C-AB14-203DE5DDF318_1_201_a.jpeg"

export const grobsJobsFeaturedImage = featuredImage;
export const grobsJobsViewJobPostImage = viewJobPostImage;
export const grobsJobsInboxOverviewImage = inboxOverviewImage;
export const grobsJobsStartMessageThreadImage = startMessageThreadImage
export const grobsJobsViewMessageThreadImage = viewMessageThreadImage;
export const grobsJobsLoginImage = loginImage;
export const grobsJobsPaginationImage = paginationImage;
export const grobsJobsRegisterImage = registerImage;
export const grobsJobsCreateJobImage = createJobImage;

export const grobsJobsShortDescription = `A forum that allows users to post jobs/tasks and contact each other`;

export const grobsJobsTitle = `Grob's Jobs`;



export const grobsJobsFullOverview = `Grob's Jobs is a website where users can browse job posts, create accounts to log in 
and inquire further about the posts, make posts themselves and message other users from the proprietary messaging service. 
It's made with Razor Pages in ASP.NET Core, so it has a C# backend and uses bootstrap for the CSS on the site. It validates data for
all forms and runs on an SQLite db file.`

export const grobsJobsViewJobPostImageDescription = `This is the page where you can view a job post in full. It displays the job 
title, the name of the poster, the date it was posted and the body of the job description. There's also an 'inquire' button, which
takes you to a page to start a message thread with the user that posted the job.`;
export const grobsJobsInboxOverviewImageDescription = `This is the inbox page. This displays all the message threads you have open
with the other users on the site.`;
export const grobsJobsStartMessageThreadImageDescription = `This is a form to begin a message thread with a user with regard to a 
particular post. You enter their name, the title of the message (this one is pre-filled, as it was a job post that brought the
user here), and of course the body.`;
export const grobsJobsViewMessageThreadImageDescription = `This is a message thread between two users. The most recent message 
is displayed at the bottom of the page. To differentiate the messages from you and another user, your name is displayed as 'you'
and is right-aligned with a different colour to the other user, whose full username is displayed and is differently coloured and aligned.`;
export const grobsJobsLoginImageDescription = `Here's the login form.`;
export const grobsJobsPaginationImageDescription = `This is the pagination selector at the bottom of the page which I wrote a proprietary
algorithm for.`;
export const grobsJobsRegisterImageDescription = `The register form.`;
export const grobsJobsCreateJobImageDescription = `And this is the form for creating a job post. You'll notice the 'help needed and
'here to help' buttons. This determines if the purpose of the job post is to offer a service or to ask for a service to be performed.`;