import { yellow, pink, purple, green } from "../colors"

export default function About(){
    return(
        <div className="content-centralised-container">
            <div className="about-container">
                <div className='page-top-spacer'></div>
                <h1 className="page-title">Who I am</h1>
                <p className="general-block-of-text" style={{marginTop: '30px'}}>I'm Jack, a freelance web developer that enjoys working on a variety of projects.
                    I'm largely front-end focused, but also more than equipped to tackle back-end operations too.
                    My experience largely comprises React, MVC/Razor Pages and Shopify. But here's a full list of my tech stack:
                </p>
                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: green, textAlign: 'center', fontWeight: 'bold'}}>GraphQL</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: pink, textAlign: 'center', fontWeight: 'bold'}}>Razor Pages</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: yellow, textAlign: 'center', fontWeight: 'bold'}}>ASP MVC</p>
                    </div>
                </div>
                
                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: purple, textAlign: 'center', fontWeight: 'bold'}}>Node.JS</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: green, textAlign: 'center', fontWeight: 'bold'}}>Shopify Liquid</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: pink, textAlign: 'center', fontWeight: 'bold'}}>CSS</p>
                    </div>
                </div>

                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: yellow, textAlign: 'center', fontWeight: 'bold'}}>EF Core</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: purple, textAlign: 'center', fontWeight: 'bold'}}>Unity</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: green, textAlign: 'center', fontWeight: 'bold'}}>App Bridge</p>
                    </div>
                </div>

                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: pink, textAlign: 'center', fontWeight: 'bold'}}>React</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: yellow, textAlign: 'center', fontWeight: 'bold'}}>WPF</p>
                    </div>
                    <div style={{width: '33%', alignContent: 'center'}}>
                        <p className="mid-sized-title" style={{color: purple, textAlign: 'center', fontWeight: 'bold'}}>Prisma</p>
                    </div>
                </div>
            </div>
            <div className="page-bottom-spacer"></div>
        </div>
    )
}