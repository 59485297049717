import { useEffect } from "react";
import ImageBlock from "../../../PageComponents/ImageBlock";
import ProjectPageTitle from "../../../PageComponents/ProjectPageTitle";
import { 
    grobsSynthFeaturedImage,
    grobsSynthFullOverview,

} from "./GrobsSynthData";

export default function GrobsJobsProjectPage(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="content-centralised-container" style={{marginBottom: "50px"}}>
            <div className='page-top-spacer'></div>
            <ProjectPageTitle title={`Grob's Synth`}/>
            <p className="general-block-of-text" style={{marginTop: "50px"}}>{grobsSynthFullOverview} 
                &nbsp;You can try it <a href="https://beautiful-banoffee-d006e8.netlify.app/" style={{color: 'blue'}} target={'_blank'} rel="noreferrer">here
            </a> (there's a more in-depth user guide at the bottom of the webpage).</p>
            <ImageBlock imageFileName={grobsSynthFeaturedImage} />
        </div>
    )
}