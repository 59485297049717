import { Link } from "react-router-dom"

export default function PageThumbnail({title, description, color, borderColor, imageFileName, imageAlt, to}){
    return(
            <Link to={to} className="thumbnail-container" style={{color: borderColor, borderColor: borderColor, textDecoration: 'none'}}>
                <div className="thumbnail-top" style={{backgroundColor: color}}>
                    <h2 className="thumbnail-title">
                        {title}
                    </h2>
                </div>
                <div className="thumbnail-body" style={{borderColor: color}}>
                    <div>
                        <img src={imageFileName} alt={imageAlt} className='thumbnail-image' />
                    </div>
                </div>
                <div className="thumbnail-bottom" style={{backgroundColor: color}}>
                    <p className="thumbnail-description">
                        {description}
                    </p>
                </div>
            </Link>
    )
}