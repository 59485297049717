import { useEffect } from "react";
import ProjectPageTitle from "../../../PageComponents/ProjectPageTitle";
import { 
    customerJourneyFullDescription,
    customerJourneyTitle,
    customerJourneyPrototypeImageExport
} from "./CustomerJourneyData";
import ImageBlock from "../../../PageComponents/ImageBlock";

export default function CustomerJourneyProjectPage(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="content-centralised-container" style={{marginBottom: "50px"}}>
            <div className='page-top-spacer'></div>
            <ProjectPageTitle title={customerJourneyTitle}/>
            <p className="general-block-of-text" style={{marginTop: "20px"}}>{customerJourneyFullDescription}</p>
            <ImageBlock imageFileName={customerJourneyPrototypeImageExport} />
        </div>
    )
}