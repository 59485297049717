import Navbar from './Navbar/Navbar.jsx';
import Home from './Pages/Home/Home.jsx'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import GrobsJobsProjectPage from './Projects/FullProjects/GrobsJobs/GrobsJobsProjectPage.jsx';
import GrobsSynthProjectPage from './Projects/FullProjects/GrobsSynth/GrobsSynthProjectPage.jsx';
import StockTakeManagementAppProjectPage from './Projects/FullProjects/StockTakeManagementApp/StockTakeManagementAppProjectPage.jsx';
import ContactForm from './Pages/Contact.jsx';
import MessageSent from './Pages/Home/MessageSent.jsx';
import About from './Pages/About.jsx';
import Footer from './Footer/Footer.jsx';
import CustomerJourneyProjectPage from './Projects/FullProjects/CustomerJourney/CustomerJourneyProjectPage.jsx';


function App() {
  return (
    <div>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <Router>
        <div>
          <div className='page' style={{paddingTop: '0'}}>
          <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/GrobsJobs' element={<GrobsJobsProjectPage />} />
              <Route path='/GrobsSynth' element={<GrobsSynthProjectPage />} />
              <Route path='/StockTakeManagementApp' element={<StockTakeManagementAppProjectPage />} />
              <Route path='/CustomerJourney' element={<CustomerJourneyProjectPage />} />
              <Route path='/Contact' element={<ContactForm />} />
              <Route path='/MessageSent' element={<MessageSent />} />
              <Route path='/About' element={<About />} />
            </Routes>
          <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
