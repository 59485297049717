import featuredImage from "../../../images/grobsSynth/202E51C5-42A2-47F4-9CA7-D1529F43785F_1_201_a.jpeg"

export const grobsSynthFeaturedImage = featuredImage;

export const grobsSynthShortDescription = `A digital synthesizer made using Javascript's Web Audio API`;

export const grobsSynthTitle = `Grob's Synth`;

export const grobsSynthFullOverview = `Grob's synth is a digital synthesizer with a React front-end, and functions written in JavaScript.
It uses the WebAudioAPI to create two sound generators (each with an option of four waveform shapes), that then have two modulators plugged 
into them to optionally distort the wave shapes. Finally, there's the sound envelope, which allows you to edit the parameters of attack 
(fade in from 0 to peak volume), decay (fade from peak volume to the sustain level), sustain (the level of volume a sound remains at when
it is held), and release (the fade out from sustain to 0). The keys you see laid out are mapped to keys on your computer keyboard, so you
can play these two octaves in a similar way to how you would on a musical keyboard.`;

